import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Container from "../components/container";
import Layout from "../containers/layout";
import ProfilePreviewGrid from "../components/profile-preview-grid";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";

export const query = graphql`
	query ProfilePageQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
			description
			keywords
		}

		projects: allSanityProject(
			sort: { fields: [publishedAt], order: DESC }
		) {
			edges {
				node {
					id
					publishedAt
					mainImage {
						crop {
							_key
							_type
							top
							bottom
							left
							right
						}
						hotspot {
							_key
							_type
							x
							y
							height
							width
						}
						asset {
							_id
							metadata {
								dimensions {
									aspectRatio
								}
							}
						}
						alt
					}
					title
					_rawExcerpt
					slug {
						current
					}
					members {
						_key
						person {
							slug {
								current
							}
							image {
								crop {
									_key
									_type
									top
									bottom
									left
									right
								}
								hotspot {
									_key
									_type
									x
									y
									height
									width
								}
								asset {
									_id
								}
							}
							name
							slug{
								current
							}
							discipline {
								name
							}
							_rawBio
							_rawResearchSummaryShort
							institution {
								name
								url
							}
							program {
								name
							}
							email
							facebook
							instagram
							linkedIn
							twitter
							vimeo
							keywords {
								value
							}
						}
						roles
					}
					videoURL
				}
			}
		}

		disciplines: allSanityDiscipline(sort: { fields: name }) {
			edges {
				node {
					name
					id
				}
			}
		}
	}
`;

const Profiles = props => {
	const { data, errors } = props;

	if (errors) {
		return (
			<Layout>
				<GraphQLErrorList errors={errors} />
			</Layout>
		);
	}

	const site = (data || {}).site;
	const projectNodes = (data || {}).projects
		? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
		: [];
	const disciplineFilters = (data || {}).disciplines
		? mapEdgesToNodes(data.disciplines)
		: [];

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		);
	}

  return (
    <Layout>
      <SEO title="Profiles" />
			<Container>
				{projectNodes && (
					<ProfilePreviewGrid
						title="Latest projects"
						nodes={projectNodes}
						filters={disciplineFilters.reverse()}
						browseMoreHref="/projects/"
					/>
				)}
			</Container>
    </Layout>
  );
};

export default Profiles;
